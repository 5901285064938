import React from "react";

import Svg from "../svg";

import useTheme from "../../../hooks/use-theme";
type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const Showtime = ({ className, color = null, size = 30 }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg
      label="Showtime"
      width={size}
      height={size}
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color || theme.Color.UnchangeableIconColor} fillRule="nonzero">
          <path d="M6.757 19.995c-.978 0-1.777-2.182-1.757-5.455 1.41 1.614 2.727 1.987 2.727 3.897 0 .924-.403 1.558-.97 1.558zm11.161 2.047V8.31h-2.37v5.53h-2.563v-5.53h-2.37v13.733h2.374V16.09h2.56v5.952h2.37zm.987-6.903c0 4.019 1.516 6.55 3.26 6.991a10.284 10.284 0 0 1-7.568 3.32c-3.071 0-5.738-1.29-7.692-3.445 1.487 0 2.807-1.622 2.807-3.361C9.712 14.194 5 14.337 5 11.44c0-.23.034-.437.098-.616l.072-.163a.897.897 0 0 1 .825-.509c.819 0 1.107.723 1.107 2.12h2.211c0-2.367-.614-3.898-2.423-4.261a10.288 10.288 0 0 1 7.707-3.471c2.782 0 5.843 1.214 7.676 3.49-1.834.277-3.368 2.92-3.368 7.11zm2.459-.681c0-2.231.655-4.463 1.853-4.463 1.425 0 1.783 3.448 1.783 4.54 0 2.39-.76 4.55-1.783 4.55-1.23 0-1.853-2.275-1.853-4.627z" />
        </g>
      </g>
    </Svg>
  );
};

export default Showtime;
