import React from "react";

import Svg from "../svg";

import useTheme from "../../../hooks/use-theme";
type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const Roku = ({ className, color = null, size = 40 }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg
      label="Roku"
      width={size}
      height={size}
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={color || theme.Color.UnchangeableIconColor}
          fillRule="nonzero"
          d="m13.028 24.796-2.543-3.643c.945-.57 1.567-1.504 1.567-2.548 0-1.694-1.585-3.074-3.541-3.104l-.066-.001h-3.445v9.29h2.405v-3.093h.728l2.167 3.099zm-4.792-4.648h-.7v-2.958h.7c.777 0 1.412.663 1.412 1.486 0 .794-.612 1.447-1.37 1.471h-.042zm7.743 4.648a3.8 3.8 0 0 0 3.81-3.803 3.801 3.801 0 0 0 -3.81-3.803 3.79 3.79 0 0 0 -3.796 3.803 3.79 3.79 0 0 0 3.796 3.803zm15.01 0c1.013 0 1.943-.565 2.396-1.077l1.095.929h.52v-7.026h-2.436v4.549c-.275.47-.657.767-1.248.767-.6 0-.874-.35-.874-1.467v-3.85h-4.988l-2.806 2.76v-2.768h-2.438v7.035h2.437v-2.854l2.93 2.854h3.066l-3.724-3.661 3.085-3.032v4.175c0 1.387.848 2.666 2.983 2.666zm-15.01-1.69c-.681 0-1.26-.85-1.26-1.902s.579-1.901 1.26-1.901c.696 0 1.275.85 1.275 1.9 0 1.052-.579 1.903-1.276 1.903z"
        />
      </g>
    </Svg>
  );
};

export default Roku;
