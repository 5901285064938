import React from "react";
import Svg from "../svg";

import useTheme from "../../../hooks/use-theme";
type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const Netflix = ({ className, color = null, size = 30 }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg
      label="Netflix"
      width={size}
      height={size}
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <g fill={color || theme.Color.UnchangeableIconColor} fillRule="nonzero">
        <path d="M16.859 25l-3.715-10v10H9.43V5h3.714l3.715 10V5h3.714v20h-3.714z" />
      </g>
    </Svg>
  );
};

export default Netflix;
