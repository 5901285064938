import React from "react";

import Svg from "../svg";
import useTheme from "../../../hooks/use-theme";
type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const Hulu = ({ className, color = null, size = 40 }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg
      label="Hulu"
      width={size}
      height={size}
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={color || theme.Color.UnchangeableIconColor}
          fillRule="nonzero"
          d="M32.624 22.073c0 .347-.292.639-.64.639h-1.389a.647.647 0 0 1-.639-.64v-3.987h-2.39v4.182c0 1.71 1.098 2.613 2.71 2.613h2.334c1.487 0 2.39-1.07 2.39-2.613v-4.182h-2.39c.014 0 .014 3.863.014 3.988m-9.24 2.82V15h2.39v9.893h-2.39zm-4.197-2.82c0-.125 0-3.988-.014-3.988h2.39v4.182c0 1.543-.903 2.613-2.39 2.613H16.84c-1.612 0-2.71-.904-2.71-2.613v-4.182h2.39v3.988c0 .347.292.639.64.639h1.389c.347 0 .64-.292.64-.64zm-9.463-3.988H8.196c-.542 0-.806.139-.806.139V15H5v9.88h2.376v-3.974c0-.348.292-.64.64-.64h1.389c.347 0 .639.292.639.64v3.987h2.39v-4.307c0-1.806-1.209-2.501-2.71-2.501z"
        />
      </g>
    </Svg>
  );
};

export default Hulu;
