import React from "react";

import Svg from "../svg";

import useTheme from "@/hooks/use-theme";

type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const DisneyPlus = ({ className, color = null, size = 30 }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg
      width={40}
      height={40}
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill={color || theme.Color.UnchangeableIconColor}
        fillRule="nonzero"
        d="M32.896 23.105c-.14 1.78-.83 4.776-5.709 6.255-3.22.964-6.264.5-7.923.081-.037.655-.11.935-.214 1.045-.141.14-1.192.744-1.77-.11-.26-.405-.392-1.14-.466-1.796-3.732-1.707-5.45-4.165-5.517-4.276-.081-.08-.933-.964-.081-2.046.8-.978 3.413-1.957 5.768-2.355.081-2.001.318-3.51.6-4.202.34-.802.77-.08 1.14.464.31.404.496 2.149.51 3.54 1.54-.074 2.452.036 4.17.346 2.236.404 3.731 1.538 3.598 2.826-.096 1.265-1.266 1.788-1.71 1.825-.467.036-1.192-.295-1.192-.295-.497-.235-.037-.441.562-.699.652-.316.504-.64.504-.64-.245-.707-3.147-1.2-6.035-1.2-.015 1.583.067 4.21.104 5.74 2.021.383 3.532.31 3.532.31s7.375-.206 7.597-4.887c.23-4.688-7.353-9.185-12.958-10.612-5.598-1.457-8.767-.442-9.041-.302-.296.147-.022.191-.022.191s.303.045.83.221c.554.177.125.464.125.464-.955.302-2.029.11-2.236-.324-.207-.434.14-.824.54-1.384.4-.588.837-.566.837-.566 6.923-2.385 15.357 1.928 15.357 1.928 7.9 3.981 9.248 8.647 9.1 10.458zm-20.895-.243c-.785.383-.245.934-.245.934 1.474 1.575 3.288 2.562 5.013 3.172.2-2.715.17-3.672.193-5.04-2.696.183-4.25.61-4.961.934z"
      />
    </Svg>
  );
};

export default DisneyPlus;
