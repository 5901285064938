import NetflixSvg from "../components/assets/sources/netflix";
import HboSvg from "../components/assets/sources/hbo";
import HuluSvg from "../components/assets/sources/hulu";
import AmazonPrimeSvg from "../components/assets/sources/amazon-prime";
import DisneyPlusSvg from "../components/assets/sources/disney-plus";
import ShowtimeSvg from "../components/assets/sources/showtime";
import NetflixFullSvg from "../components/assets/sources/netflix-full";
import ImdbSvg from "../components/assets/sources/imdb";
import RokuSvg from "../components/assets/sources/roku";
import PeacockSvg from "../components/assets/sources/peacock";
import TraktSvg from "@/components/assets/sources/trakt";
import LetterboxdSvg from "@/components/assets/sources/letterboxd";

const USABLE_SOURCE_ALTIDS = [
  "mhsrc-netflixinstant",
  "mhsrc-hbomax",
  "mhsrc-hulu",
  "mhsrc-amazonprime",
  "mhsrc-disneyplus",
  "mhsrc-showtime",
  "mhsrc-rokuchannel",
  "mhsrc-peacock",
];
const isUsableSource = (source) => {
  return USABLE_SOURCE_ALTIDS.includes(source.altId);
};

export const getWatchUrlForSource = (source, item) => {
  const { altId: sourceAltId } = source;
  const { formats } = source.context;

  if (formats.length) {
    for (let format of formats) {
      const { id: formatId } = format;
      if (format.consumeWebUrl && sourceAltId !== "mhsrc-showtime") {
        return format.consumeWebUrl;
      } else if (format.viewWebUrl) {
        return format.viewWebUrl;
      } else if (formatId) {
        switch (sourceAltId) {
          case "mhsrc-hbomax":
            const contentType = item.mhid.startsWith("mhmov")
              ? "feature"
              : "series";
            return `https://play.hbomax.com/${contentType}/urn:hbo:${contentType}:${formatId}`;
          case "mhsrc-rokuchannel":
            return `https://therokuchannel.roku.com/details/${formatId}`;
          case "mhsrc-showtime":
            const splitIndex = formatId.indexOf("_");
            if (splitIndex !== -1) {
              const type = formatId.substring(0, splitIndex);
              const id = formatId.substring(splitIndex + 1);
              return `https://www.showtimeanytime.com/#/${type}/${id}`;
            }
            break;
          default:
            break;
        }
      }
    }
  }
  return null;
};

export const getWatchUrlForItem = (item, selectedSources = []) => {
  try {
    const { sources: availableSources = [] } = item;
    const usableSources = availableSources.filter((o) => isUsableSource(o));
    const preferredSources = usableSources.filter(({ mhid }) =>
      selectedSources.includes(mhid)
    );
    const sources =
      preferredSources.length > 0 ? preferredSources : usableSources;
    const allUrls = sources
      .map((source) => {
        // We loop over all sources (in priority order) and try to find the best url
        const url = getWatchUrlForSource(source, item);
        if (url) {
          return { url, source: source.mhid };
        }
        return null;
      })
      .filter((o) => o);

    return allUrls.length > 0 ? allUrls[0] : null;
  } catch (err) {
    // Do nothing
  }
  return null;
};

export type FilterSource = {
  name: string;
  slug: string;
  color: string;
  mhid: string;
  Icon: any; // TODO:
  isFilter: boolean;
  isImporter: boolean;
};
export type ImporterSource = {
  name: string;
  slug: string;
  color: string;
  url: string;
  loginUrl: string;
  permissionsUrl: string;
  loginWaitForUrl: string;
  exactLoginUrl?: boolean;
  Icon: any; // TODO:
  IconLarge?: any; // TODO:
  isFilter: boolean;
  isImporter: boolean;
};
type Source = FilterSource | ImporterSource | (FilterSource & ImporterSource);

// When adding a new source, be sure to:
// 1. Update the sourceData object below
// 2. Add a source svg within components/assets/sources
// 3. Update the USABLE_SOURCE_ALTIDS object
// 4. Update both share-image and share-image-large
const sourceData: Source[] = [
  {
    name: "Netflix",
    slug: "netflix",
    color: "#E60915",
    mhid: "mhsrcwRw33Yek3hvzTpbqzT7hL9EOAeyec7Y61npmukn",
    url: "https://www.netflix.com/viewingactivity",
    loginUrl:
      "https://www.netflix.com/login?nextpage=https%3A%2F%2Fwww.netflix.com%2Fviewingactivity",
    permissionsUrl: "*.netflix.com/*",
    loginWaitForUrl: "https://www.netflix.com/viewingactivity",
    Icon: NetflixSvg,
    IconLarge: NetflixFullSvg,
    isImporter: true,
    isFilter: true,
  },
  {
    name: "IMDb",
    slug: "imdb",
    color: "#F5C518",
    Icon: ImdbSvg,
    url: "https://www.imdb.com/list/ratings",
    loginUrl: "https://www.imdb.com/registration/signin",
    permissionsUrl: "https://*.imdb.com/*",
    loginWaitForUrl: "https://www.imdb.com/?ref_=login",
    isImporter: true,
    isFilter: false,
  },
  {
    name: "TraktTv",
    slug: "trakttv",
    color: "#de1219",
    Icon: TraktSvg,
    url: "https://www.trakt.tv/dashboard",
    loginUrl: "https://www.trakt.tv/auth/signin",
    permissionsUrl: "https://*.trakt.tv/*",
    loginWaitForUrl: "https://www.trakt.tv/home",
    isImporter: true,
    isFilter: false,
  },
  {
    name: "HBO MAX",
    slug: "hbomax",
    color: "#000000",
    mhid: "mhsrcMIBj5lQZMFTPctkMgmlmcXM438x8Ad467b7VPrt",
    Icon: HboSvg,
    isImporter: false,
    isFilter: true,
  },
  {
    name: "Hulu",
    slug: "hulu",
    color: "#3DBB3D",
    mhid: "mhsrczhUrCLrA4exlm5zdaH9mwEcHbLypC4489PjuylG",
    Icon: HuluSvg,
    isImporter: false,
    isFilter: true,
  },
  {
    name: "Amazon Prime",
    slug: "amazonprime",
    color: "#00A8E1",
    mhid: "mhsrcc6IRGx4BGkkGF3txCAJFZuNDJSAE1nAJVuAIBzD",
    Icon: AmazonPrimeSvg,
    isImporter: false,
    isFilter: true,
  },
  {
    name: "Disney+",
    slug: "disneyplus",
    color: "#101C50",
    mhid: "mhsrcRvKwoptl1Aa5qUA6ktXJMDXDQjjOhvBpObq73YW",
    Icon: DisneyPlusSvg,
    isImporter: false,
    isFilter: true,
  },
  {
    name: "Showtime",
    slug: "showtime",
    color: "#000000",
    mhid: "mhsrc9BffnAacAboP4H4sKipVAI3BvArczO69lnSbhqR",
    Icon: ShowtimeSvg,
    isImporter: false,
    isFilter: true,
  },
  {
    name: "The Roku Channel",
    slug: "rokuchannel",
    color: "#000000",
    mhid: "mhsrciRLGzIN6dPQkIJ6DPdqCPEJ8pmZInxgymIT26Ic",
    Icon: RokuSvg,
    isImporter: false,
    isFilter: true,
  },
  {
    name: "Peacock",
    slug: "peacock",
    color: "#000000",
    mhid: "mhsrcr4NSownPYlBeCdbrHotX4W3I0LvypDeqBZ4p5nM",
    Icon: PeacockSvg,
    isImporter: false,
    isFilter: true,
  },
  {
    name: "Letterboxd",
    slug: "letterboxd",
    color: "#00c030",
    Icon: LetterboxdSvg,
    url: "https://letterboxd.com/activity/you",
    loginUrl: "https://letterboxd.com/sign-in",
    loginWaitForUrl: "https://letterboxd.com/",
    permissionsUrl: "https://*.letterboxd.com/*",
    exactLoginUrl: true,
    isImporter: true,
    isFilter: false,
  },
];

// One list for usable sources
export const SOURCES = sourceData.filter((s) => s.isFilter) as FilterSource[];
export const IMPORT_SOURCES = sourceData.filter(
  (s) => s.isImporter
) as ImporterSource[];
