import React from "react";

import Svg from "../svg";
import useTheme from "../../../hooks/use-theme";

type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const AmazonPrime = ({ className, color = null, size = 40 }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg
      label="AmazonPrime"
      width={size}
      height={size}
      className={className}
      preserveAspectRatio="xMidYMid meet"
    >
      <g fill={color || theme.Color.UnchangeableIconColor} fillRule="nonzero">
        <path d="M20.861 15.488c-.656-.05-2.083-.066-4.282.366-3.133.617-4.579 3.36-4.579 5.508C12 23.509 14.169 26 17.423 26c2.49 0 3.894-1.42 4.414-2.086a.287.287 0 0 1 .459.016c.128.19.312.443.55.722.253.298.593.597.868.82a.713.713 0 0 0 .916-.024l2.157-1.949a.624.624 0 0 0 .08-.854 37.75 37.75 0 0 1-.455-.598c-.579-.777-.554-1.394-.554-1.394v-7.22c0-.869-.555-4.433-6.387-4.433-4.764 0-6.216 3.216-6.588 4.395-.071.225.084.457.329.496l2.958.474a.418.418 0 0 0 .438-.211c.325-.623 1.268-2.138 2.742-2.138 1.857 0 1.929 1.44 1.929 1.44v1.664a.38.38 0 0 1-.418.368zm.467 2.67v2.106c0 1.1-.799 2.063-1.93 2.301a4.877 4.877 0 0 1-1.012.1c-2.457 0-2-2.994-.867-3.886.859-.675 2.603-.904 3.398-.975.22-.02.41.145.41.354zM8.043 27.27s-.272.505.556 1.493c.827.988 5.03 4.237 10.287 4.237 4.327 0 6.148-.872 8.017-1.882 1.868-1.011 3.327-2.474 3.067-2.948-.26-.474-1.758.729-3.305 1.273-.967.34-3.924 1.307-8.11 1.307s-8.396-2.871-9.294-3.48c-.899-.608-1.218 0-1.218 0z" />
        <path d="M20.861 15.488c-.656-.05-2.083-.066-4.282.366-3.133.617-4.579 3.36-4.579 5.508C12 23.509 14.169 26 17.423 26c2.49 0 3.894-1.42 4.414-2.086a.287.287 0 0 1 .459.016c.128.19.312.443.55.722.253.298.593.597.868.82a.713.713 0 0 0 .916-.024l2.157-1.949a.624.624 0 0 0 .08-.854 37.75 37.75 0 0 1-.455-.598c-.579-.777-.554-1.394-.554-1.394v-7.22c0-.869-.555-4.433-6.387-4.433-4.764 0-6.216 3.216-6.588 4.395-.071.225.084.457.329.496l2.958.474a.418.418 0 0 0 .438-.211c.325-.623 1.268-2.138 2.742-2.138 1.857 0 1.929 1.44 1.929 1.44v1.664a.38.38 0 0 1-.418.368zm.467 2.67v2.106c0 1.1-.799 2.063-1.93 2.301a4.877 4.877 0 0 1-1.012.1c-2.457 0-2-2.994-.867-3.886.859-.675 2.603-.904 3.398-.975.22-.02.41.145.41.354zM29.763 30.974c.616.347 2.986-2.846 2-4.431-.82-1.32-4.254.158-4.508.335-.254.177-.254.388-.254.388s-.067.53 1.05.388c1.12-.14 1.61-.287 1.95.025.338.31.22.946 0 1.67s-.764 1.328-.238 1.625z" />
      </g>
    </Svg>
  );
};

export default AmazonPrime;
