import React from "react";

import Svg from "../svg";

import useTheme from "@/hooks/use-theme";

type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const Imdb = ({ className, color = null, size = 30 }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg
      label="IMDb"
      width="70"
      height="28"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <path
        fill={color || theme.Color.UnchangeableIconColor}
        fillRule="nonzero"
        d="M0 0h7.467v28H0zM23.22 0l-1.675 13.08-1.036-7.112c-.302-2.284-.591-4.273-.867-5.968h-9.375v28H16.6l.022-18.488L19.29 28h4.512L26.33 9.1l.023 18.9h6.314V0H23.22zM43.57 5.04c.293.165.48.428.561.784.082.357.123 1.168.123 2.434v10.855c0 1.864-.123 3.006-.368 3.425-.246.421-.9.63-1.963.63V4.789c.805 0 1.355.084 1.647.251zM43.494 28c1.772 0 3.098-.095 3.979-.285.88-.19 1.619-.524 2.218-1.001.599-.478 1.018-1.14 1.259-1.987.24-.845.383-2.524.383-5.033V9.86c0-2.648-.105-4.424-.269-5.327-.164-.904-.575-1.724-1.233-2.46-.657-.738-1.617-1.267-2.878-1.59C45.69.163 43.63 0 40.052 0h-5.519v28h8.96zM62.845 20.932c0 1.337-.07 2.182-.206 2.534-.137.352-.734.529-1.187.529-.442 0-.736-.17-.885-.51-.15-.34-.223-1.118-.223-2.332V13.85c0-1.259.065-2.044.196-2.356.131-.312.417-.468.858-.468.452 0 1.06.178 1.214.535.155.357.233 1.12.233 2.288v7.084zM53.2 0v27.638h6.676l.462-1.76c.604.707 1.27 1.238 1.998 1.592.728.354 1.815.53 2.656.53 1.172 0 2.184-.298 3.036-.893.853-.596 1.395-1.3 1.626-2.112.23-.812.346-2.045.346-3.703v-7.754c0-1.669-.039-2.758-.115-3.268-.078-.511-.305-1.033-.683-1.566-.38-.533-.93-.948-1.652-1.243-.721-.296-1.573-.443-2.555-.443-.852 0-1.944.164-2.672.49-.728.325-1.388.82-1.98 1.482V0H53.2z"
      />
    </Svg>
  );
};

export default Imdb;
