import React from "react";
import Svg from "../svg";
import useTheme from "../../../hooks/use-theme";
type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const Peacock = ({ className, color = null, size = 40 }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg
      label="Peacock"
      width={size}
      height={size}
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g fill="none">
        <g fillRule="nonzero">
          <path
            d="M16.957 7C12.567 7 9 10.52 9 14.854V33h3.766V22.93l1.372 1.453 5.538-2.142c3.168-1.207 5.213-4.014 5.213-7.362C24.889 10.52 21.322 7 16.957 7zm1.45 12.217l-5.796 2.227v-6.403c0-2.557 1.966-4.43 4.32-4.43 2.355 0 4.347 1.873 4.347 4.43 0 1.62-.802 3.391-2.872 4.176"
            fill={color || theme.Color.UnchangeableIconColor}
          />
          <path
            d="M29.321 25.074a1.643 1.643 0 0 0-1.535 1.011 1.582 1.582 0 0 0-.09.957c.073.325.217.614.452.848.235.235.523.38.848.452.325.072.65.036.957-.09a1.643 1.643 0 0 0 1.011-1.535c0-.434-.18-.849-.487-1.174-.289-.307-.704-.47-1.156-.47"
            fill={color || theme.Color.UnchangeableIconColor}
          />
          <path
            d="M29.321 20.542a1.643 1.643 0 0 0-1.535 1.011 1.582 1.582 0 0 0-.09.957c.073.325.217.614.452.848.235.235.523.38.848.452.325.072.65.036.957-.09a1.643 1.643 0 0 0 1.011-1.535c0-.434-.18-.849-.487-1.174a1.638 1.638 0 0 0-1.156-.47"
            fill={color || theme.Color.UnchangeableIconColor}
          />
          <path
            d="M29.321 29.587a1.643 1.643 0 0 0-1.535 1.011 1.582 1.582 0 0 0-.09.958c.073.325.217.613.452.848.235.235.523.38.848.452.325.072.65.036.957-.09a1.643 1.643 0 0 0 1.011-1.535c0-.217-.036-.434-.126-.632a1.778 1.778 0 0 0-.361-.542 1.894 1.894 0 0 0-.542-.361 1.687 1.687 0 0 0-.614-.108"
            fill={color || theme.Color.UnchangeableIconColor}
          />
          <path
            d="M29.321 16.028a1.643 1.643 0 0 0-1.535 1.011 1.582 1.582 0 0 0-.09.957c.073.325.217.614.452.848.235.235.523.38.848.452.325.072.65.036.957-.09a1.643 1.643 0 0 0 1.011-1.535c0-.217-.036-.434-.126-.632a1.778 1.778 0 0 0-.361-.542 1.894 1.894 0 0 0-.542-.36c-.198-.091-.397-.11-.614-.11"
            fill={color || theme.Color.UnchangeableIconColor}
          />
          <path
            d="M29.321 11.514a1.643 1.643 0 0 0-1.535 1.011 1.582 1.582 0 0 0-.09.957c.073.325.217.614.452.849.235.234.523.379.848.45.325.073.65.037.957-.09a1.643 1.643 0 0 0 1.011-1.534c0-.433-.18-.849-.487-1.174a1.638 1.638 0 0 0-1.156-.47"
            fill={color || theme.Color.UnchangeableIconColor}
          />
          <path
            d="M29.321 7a1.643 1.643 0 0 0-1.535 1.011 1.582 1.582 0 0 0-.09.957c.073.325.217.614.452.849.235.234.523.379.848.451.325.072.65.036.957-.09a1.643 1.643 0 0 0 1.011-1.535c0-.433-.18-.849-.487-1.174A1.638 1.638 0 0 0 29.32 7"
            fill={color || theme.Color.UnchangeableIconColor}
          />
        </g>
      </g>
    </Svg>
  );
};
export default Peacock;
