import React from "react";
import Svg from "../svg";

type IconProps = {
  className?: string;
  color?: string;
  size?: number;
};
const TraktSvg = ({ className }: IconProps) => (
  <Svg
    width="70"
    height="13"
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M9.912 0v2.324H6.308v10.101H3.652V2.324H0V0h9.912zm10.291 0c2.609 0 4.174 1.707 4.174 3.984 0 2.181-1.376 3.367-2.704 3.699l2.799 4.742h-3.036l-2.418-4.41h-1.945v4.41h-2.656V0h5.786zM36.66 0l4.79 12.425h-3.035l-.76-2.086h-5.31l-.76 2.086H28.55L33.34 0h3.32zm11.714 0v5.549L52.784 0h3.273l-4.932 5.833 5.264 6.592h-3.32l-3.747-4.98-.948 1.139v3.794h-2.656V0h2.656zM70 0v2.324h-3.604v10.101H63.74V2.324h-3.652V0H70zM35 2.656l-1.944 5.359h3.888L35 2.655zm-15.129-.332h-2.798V5.69h2.75c1.092 0 1.898-.664 1.898-1.707 0-1.044-.806-1.66-1.85-1.66z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Svg>
);

export default TraktSvg;
